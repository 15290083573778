/* IDs */
#footer{
    /* Rectangle 1 */
    height: 75px;
    width: 100%;
    background: #CCCCCC;
    display: flex;
    justify-content: space-between;
}

#footer_logo{
    height: 50px;
    aspect-ratio: 1/1;
    margin-top: 12px;
    margin-left: 12px;
}

#footer_photo{
    height: 50px;
    position: absolute;
    border-radius: 100%;
}

#footer_linkedin{
    background: #B80F0A;
    display: flex;
    width: 140px;
    height: 60px;
    border-radius: 25px;
    margin-top: 10px;
    margin-right: 15px;
}

#footer_font{
    font-size: 20px;
    text-align: center;
    justify-content: center;
    display: flex;
    color: white;
    width: 100%;
    height: auto;
    margin-top: auto;
    text-decoration: none;
}