/*** defined tags ***/
#Pong_playerArea{
    display: flex;
    width: 500px;
}
#Pong_container2{
    display: block;
    margin: auto;
    justify-content: center;
}
#Pong_title{
    text-align: center;
}

/*** User classes ***/
.Pong_mainContainer{
    font-size: 2.5rem;
    text-align: center;
    padding: 5px;
    background-color: whitesmoke;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    height: 100vh;
    aspect-ratio: 2/3;
}

.Pong_subContainer{
    min-height: 400px;
    max-width: 80vw; 
    aspect-ratio: 1/1;
    background-color: white;
    opacity: 60%;    
    display: block;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;

}

.Pong_subContainer:hover{   
    min-height: 400px;
    max-width: 80vw; 
    width: auto;
    aspect-ratio: 1/1;
    background-color: white;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    opacity: 100%;
    margin-top: 10px;

}

.Pong_startButton{
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.Pong_Player{
    width: 500px;
}

@media (max-width: 900px ) or (pointer: coarse){
    .Pong_mainContainer{
        font-size: 2.5rem;
        text-align: center;
        height: 90vh;
        aspect-ratio: 1/3;
    }
    .Pong_subContainer{
        height: 60vh;
        aspect-ratio: 1/3;
    }
    .Pong_subContainer:hover{   
        height: 60vh;
        aspect-ratio: 1/3;
    }
}