

#navbar {
/* Rectangle 1 */
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 150px;
  left: 0px;
  top: 0px;
  justify-content: space-between;
  background: #DCDCDC;
  z-index: 8;

}

#navbar_name{
  display: flex;
  margin-top: 10px;
}

#navbar_logo{
  border-radius: 100%;
  /* Ellipse 1 */

  position: absolute;
  width: 100px;
  height: 100px;
  left: 10px;
  top: 25px;

  background: #404040;
}

#navbar_items_list{
  list-style-type: none;
  width: auto;
  height: 100%; 
  margin: 0 10px auto auto;
  /* Let's remove the bullet points */
  /* display the list flex and align the items centered */
  display: flex;
  justify-content: right;
}

#navbar_checkbox {
  display: none; /* Hide the checkbox itself */
}

#navbar_linkedin{
  text-decoration: none;
  color: #e7e7e7;
}

#navbar_links_linkedin{
  background: #B80F0A;
}

.navbar input{
  position: absolute;
  top: 15%;
  left: 0.75%;
  height: 100px; 
  width: 100px;
  display: none;
}

.navbar_logo{
  /*Let's make the anchor display block.
    This will ensure when we get the full width of the container.
  */
  border-radius: 100%;
  margin: auto;
  justify-items: left;
  float: left;
}

.navbar_menu_items{
  margin-top: 50px;
}

.navbar_links{
  /* Home button */
  height: 60px;
  width: 100px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  background: black;
  border-radius: 50px;
}

.navbar_font{
  /* Naeem Mannan */

  position: absolute;
  left: 125px;
  top: 50px;

  font: italic 1.5em "Fira Sans", serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;

  color: black;
}
.navbar_font2{
  color: white;
  text-align: center;
}

/* When a user hovers, focuses or if the link is active change the background */
.navbar ul a:hover,
.navbar ul a:focus,
.navbar ul .active {
  background-color: #272727;
}

@media (max-width: 1200px ) and (pointer: coarse){
  #navbar_logo{
    height: 50px;
    width: 50px;
    margin-top: 5px;
    margin-left: 12px;
  }

  #navbar_items_list{
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top: 100px;
    justify-content: center;
  }

  #navbar {
    width: 100%;
    height: 100px;
    background: #DCDCDC;
    z-index: 1;
    display: flex;
    overflow: visible;
  }

  #navbar_name{
    margin-top: -5px;
  }

  /* When the checkbox is checked, slide the menu in */
  .navbar_menu_items.open {
    transform: translateY(calc(0vh));
  }

  .navbar_menu_items {
    display: flex;
    justify-content: center;
    background-color: rgba(128, 0, 0, 0.9);
    width: 100%;
    height: 100vh;
    margin: auto;
    position: fixed;
    transform: translateY(calc(-130vh)); /* Initially off-screen */
    transition: transform 0.3s ease-in-out; /* Smooth transition for sliding in */
  }

  .navbar_hamburgerlines {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 0vw;
    height: 21px;
    cursor: pointer;
    position: absolute;
    right: 10%;
    top: 40px;
    z-index: 3;
  }

  .navbar_hamburgerline {
    width: 30px;
    height: 3px;
    background-color: black; /* Change to match your design */
    border-radius: 5px;
    transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth animations */
  }

  /* Active state for the first line */
  .line1_active {
    transform: rotate(45deg) translate(5px, 7.5px);
  }

  /* Active state for the second (middle) line */
  .line2_active {
    opacity: 0; /* Hide the middle line */
  }

  /* Active state for the third line */
  .line3_active {
    transform: rotate(-45deg) translate(5px, -7.5px);
  }

  .navbar_font{
    font: italic 1.25rem "Fira Sans", serif;
    color: black;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    left: 80px;
    top: 50px;
  }

  .navbar_font2{
    display: flex;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    justify-content: center;
    text-decoration: none;
  }

  .navbar_profile_link{
    display: flex;
    text-align: left;
    margin-top: 0;
  }

  /* Navbar links styling */
  .navbar_links {
    color: black;
    width: 50vw;
    margin-top: 50px;
    z-index: 9;
  }

  /* When a user hovers, focuses or if the link is active change the background */
  .navbar ul a:hover,
  .navbar ul a:focus,
  .navbar ul .active {
    background: none;
  }
}