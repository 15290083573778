#projects{
    padding-top: 150px;
    background: linear-gradient(360deg, #e5de00 47.75%, #7BB4E3 59.21%);
    background-blend-mode: screen;
    overflow: hidden;
}

#projects_carousel{
    height: auto;
    margin-bottom: 10vh;
}

#projects_ProjectItem{
    margin-top: 200px;
}

#projects_Pong{
    width: 40vw;
    background-color: rgb(0, 0, 0, 0.8);
    height: 50px;
    border-radius: 20px;
    border-style: solid;
    border-width: 3px;
    border-color: whitesmoke;
    text-decoration: none;
    margin-bottom: 100px;
}

.projects_Video{
    display: block;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    aspect-ratio: 4/5;
    width: auto;
    height: 400px;
    margin-top: 10px;
    border-radius: 20px;
    margin-bottom: 10px;
    border-style: solid;
    border-width: 1px;
}

#projects_ProjectsList{
    display: inline;
    margin: auto;
    list-style-type: none;
    width: 60%;
}

.projects_projectImage{
    aspect-ratio: 4/5;
    width: auto;
    height: 400px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 10px;
    margin-bottom: 30px;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;

}

.projects_Photos{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates 3 equal columns */
    gap: 10px; /* Space between grid items */
}

.projects_projectName{
    padding-bottom: 30px;
    font-size: 2.5vh;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.projects_projectDiv{
    list-style-type: none;
    background-color: white;
    height: auto;
    width: 350px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 20px;
}

.projects_generalFont{
    font: italic 2em "Fira Sans", serif;
    color: white;
    text-align: center;
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
}

.projects_background{
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    background: rgb(0, 0, 0, 0.3);
    border-radius: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-right: 20px;
    margin-left: 20px;
}

@media (max-width: 1280px ) and (pointer:coarse) {
    #projects{
        padding-top: 100px;
    }
    .projects_generalFont{
        font: italic 5vw "Fira Sans", serif;
    }

    .projects_projectName{
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 3.5vw;
        text-align: center;
    }
    .projects_projectImage{
        width: 30vw;
        height: auto;
    }

    .projects_projectDiv{
        min-height: 35vh;
        width: 40vw;
        margin-top: 10px;
    }
    .projects_Video{
        height: 22.5vh;
    }

    .projects_Photos{
        display: inline;
        grid-template-columns: 0; /* Creates 3 equal columns */
    }
    
}
