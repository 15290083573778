#interests{
    padding-top: 150px;
    background: linear-gradient(360deg, #e5de00 47.75%, #7BB4E3 59.21%);
    background-blend-mode: screen;
    overflow: hidden;
}

#interests_mct{
    justify-content: space-evenly;
    columns: 2;
    list-style: none;
}

#interests_boardgames{
    display: flex;
    columns: 3;
    width: 100%;
    list-style: none;
    justify-content: space-evenly;
}

.interests_mctPic{
    justify-content: center;
    list-style-type: none;
}


.interests_polaroid{
    justify-content: center;
    display: block;
    margin: auto;
    background-color: white;
    aspect-ratio: 1/2;
    height: 60vh;
    list-style-type: none;
    margin-top: 20px;
    border-radius: 20px;
}

.interests_boardgameItem{
    width: 33%;
}

.interests_boardgame{
    aspect-ratio: 1/1;
    height: 40vh;
    border-radius: 20px;
    border-style: solid;
    border-width: 2px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.interests_MCT{
    aspect-ratio: 1/1;
    height: 40vh;
    border-radius: 20px;
    border-style: solid;
    border-width: 2px;
    margin-bottom: 10px;
    justify-content: center;
    margin: auto;
}

.interests_generalFont{
    font: italic 4vh "Fira Sans", serif;
    color: white;
    margin-left: 3vw;
    margin-right: 3vw;
    text-align: center;
}

.interests_generalFont2{
    font: italic 3vh "Fira Sans", serif;
    text-align: center;
}

@media (max-width: 1280px ) and (pointer:coarse) {

    #interests{
        background: url("../assets/pictures/Backgroundmain.jpg");
        min-height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 75px;
        overflow: hidden;
    }

    #interests_mct{
        display: inline;
        columns: 1;
        width: 100%;
    }
    
    
    #interests_boardgames{
        columns: 1;
        display: block;
        margin: auto;
        justify-content: center;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }
    
    .interests_mctPic{
        justify-content: center;
        list-style-type: none;
    }
    
    
    .interests_polaroid{
        padding-bottom: 2vh;
    }
    
    
    .interests_boardgame{
        margin: auto;
    }
    
    .interests_MCT{
        aspect-ratio: 1/1;
        height: 40vh;
        border-radius: 20px;
        border-style: solid;
        border-width: 2px;
        margin-bottom: 10px;
        justify-content: center;
        margin: auto;
    }
    
    .interests_generalFont{
        font: italic 3vh "Fira Sans", serif;
        color: white;
        margin-left: 2vw;
        margin-right: 2vw;
    }
    
}