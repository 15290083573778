#contacts{
    min-height: 100vh;
    padding-top: 150px;
    background: linear-gradient(360deg, #e5de00 47.75%, #7BB4E3 59.21%);
    background-blend-mode: screen;
    overflow: hidden;
}

#contacts_linkedin{
    padding-top: 15px;
    padding-bottom: 15px;
}

#contacts_linkedinLink{
    display: flex;
    text-decoration: none;
    width: 40%;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgb(255,0,0, 0.85);
    border-radius: 5px;
    color: white;
    font-size: 1rem;
}

.contacts_logos{
    justify-content: center;
    margin-top: 10px;
    padding-top: 10px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.contacts_bubble{
    margin: auto;
    justify-content: center;
    background: rgba(53,56,57,0.65);
    min-width: 100px;
    width: 40%;
    border-radius: 17px;
    padding-bottom: 1px;
}

.contacts_generalFont{
    color: white;
    text-align: center;
    font-size: 1.5rem;
}

@media (max-width: 1280px ) and (pointer: coarse){
    #contacts{
        padding-top: 100px;
    }

    #contacts_linkedinLink{
        width: 80%;
        font-size: 0.8rem;
    }

    .contacts_generalFont{
        color: white;
        text-align: center;
        font-size: 1rem;
    }
}